// @ts-ignore
import {
  AdPurposeEnum,
  VerifyItemEnum,
  DateIntervals,
  PeriodIntervalsEnum,
  DashboardPeriodIntervalsEnum,
  DefaultOrderByEnum,
  AdStatus,
  GameStatus,
  AdSpaceStatus,
  PaymentTypeEnum,
  TitleTypeEnum,
  ReportPlatformEnum,
  PeriodTypeEnum,
  RecurrenceTypeEnum,
  DayOfTheWeekEnum,
  GameRatings,
  GameGenres,
  ReportingTimezones,
  CustomerTypeEnum,
  ReportLastStatus,
  CampaignTypeEnum,
} from '@shared/enums'
import { humanize } from '@src/shared/string'

import {
  DimensionEnum,
  MetricEnum,
  PlatformOsEnum,
  GamePlatformEnum,
  ThirdPartyAdPlatformCostProviderEnum,
} from '@graphql-types'

import { toObject } from './array'

// https://cli.vuejs.org/guide/mode-and-env.html
export const ENV_MODE = {
  DEV: 'development',
  PREVIEW: 'preview',
  TEST: 'test',
  PROD: 'production',
}

// const isNodeDevAndVueVercelDev =
//   process.env.NODE_ENV === ENV_MODE.DEV ||
//   process.env.VUE_APP_VERCEL_ENV === ENV_MODE.DEV ||
//   process.env.VUE_APP_VERCEL_ENV === ENV_MODE.PREVIEW

export const CI_CONTEXT = {
  DEV: 'dev',
  TEST: 'test',
  PROD: 'prod',
}

export const GQL_CLIENT_NAMES = {
  DEFAULT_CLIENT: 'defaultClient',
}

export const ORG_TYPES = {
  GAME_PUBLISHER: 'Game Developer',
  AGENCY: 'Agency',
  ADVERTISER: 'Advertiser',
  SUPER_ADMIN: 'Super Admin',
  THIRD_PARTY: 'Third Party',
}

export const ROLE_TYPES = {
  ACCOUNT_MANAGER: 'ACCOUNT_MANAGER',
  ADMIN: 'ADMIN',
  OWNER: 'OWNER',
  DEVELOPER: 'DEVELOPER',
  FINANCE: 'FINANCE',
  FULL_ACCESS: 'FULL_ACCESS',
  REPORTING: 'REPORTING',
}

// TODO - Remove EXCLUDED_ASPECT_RATIO_FOR_NON_FPSA_VALUES after we release for non-FPSA
export const EXCLUDED_ASPECT_RATIO_FOR_NON_FPSA_VALUES = [
  'EIGHT_POINT_ZERO_NINE_BY_ONE',
  'EIGHT_BY_ONE',
  'SIX_POINT_FOUR_BY_ONE',
  'SIX_BY_ONE',
  'ONE_POINT_TWO_BY_ONE',
  'ONE_BY_THREE_POINT_SEVENTY_FIVE',
  'ONE_BY_TWO',
  '8.09:1',
  '8:1',
  '6.4:1',
  '6:1',
  '1.2:1',
  '1:3.75',
  '1:2',
]

// TODO - Remove EXCLUDED_ASPECT_RATIO_FOR_NON_FPSA_VALUES after we release for non-FPSA
export const EXCLUDED_VIDEO_ASPECT_RATIO_FOR_NON_FPSA_VALUES = [
  'ONE_BY_ONE',
  'FOUR_BY_THREE',
  'NINE_BY_SIXTEEN',
  '1:1',
  '4:3',
  '9:16',
]

export const VIDEO_ASPECT_RATIO_VALUES = {
  ONE_BY_ONE: '1:1',
  FOUR_BY_THREE: '4:3',
  SIXTEEN_BY_NINE: '16:9',
  NINE_BY_SIXTEEN: '9:16',

  '1:1': 'ONE_BY_ONE',
  '4:3': 'FOUR_BY_THREE',
  '16:9': 'SIXTEEN_BY_NINE',
  '9:16': 'NINE_BY_SIXTEEN',
}

export const ASPECT_RATIO_VALUES = {
  ONE_BY_ONE: '1:1',
  FOUR_BY_THREE: '4:3',
  SIXTEEN_BY_NINE: '16:9',
  TWENTY_ONE_BY_NINE: '21:9',
  FORTY_BY_NINE: '40:9',
  TWO_BY_THREE: '2:3',
  THREE_BY_TWO: '3:2',
  THREE_BY_FOUR: '3:4',
  NINE_BY_SIXTEEN: '9:16',
  NINE_BY_TWENTY_ONE: '9:21',
  NINE_BY_FORTY: '9:40',
  SIXTY_BY_NINE: '60:9',
  EIGHT_BY_ONE: '8:1',
  EIGHT_POINT_ZERO_NINE_BY_ONE: '8.09:1',
  SIX_BY_ONE: '6:1',
  SIX_POINT_FOUR_BY_ONE: '6.4:1',
  ONE_POINT_TWO_BY_ONE: '1.2:1',
  ONE_BY_THREE_POINT_SEVENTY_FIVE: '1:3.75',
  ONE_BY_TWO: '1:2',

  '1:1': 'ONE_BY_ONE',
  '4:3': 'FOUR_BY_THREE',
  '16:9': 'SIXTEEN_BY_NINE',
  '21:9': 'TWENTY_ONE_BY_NINE',
  '40:9': 'FORTY_BY_NINE',
  '2:3': 'TWO_BY_THREE',
  '3:2': 'THREE_BY_TWO',
  '3:4': 'THREE_BY_FOUR',
  '9:16': 'NINE_BY_SIXTEEN',
  '9:21': 'NINE_BY_TWENTY_ONE',
  '9:40': 'NINE_BY_FORTY',
  '60:9': 'SIXTY_BY_NINE',
  '8:1': 'EIGHT_BY_ONE',
  '8.09:1': 'EIGHT_POINT_ZERO_NINE_BY_ONE',
  '6:1': 'SIX_BY_ONE',
  '6.4:1': 'SIX_POINT_FOUR_BY_ONE',
  '1.2:1': 'ONE_POINT_TWO_BY_ONE',
  '1:3.75': 'ONE_BY_THREE_POINT_SEVENTY_FIVE',
  '1:2': 'ONE_BY_TWO',
}

export const AdRatioTypes = {
  IAB: 'IAB',
  DEPRECATING: 'DEPRECATING',
  OTHER: 'OTHER',
}

export const ImageRatioResolutionMap = {
  '8.09:1': { min: [728, 90], max: [4000, 494] },
  '8:1': { min: [1024, 128], max: [4000, 500] },
  '60:9': { min: [1020, 153], max: [9600, 1440] },
  '6.4:1': { min: [320, 50], max: [4000, 625] },
  '40:9': { min: [1040, 234], max: [6400, 1440] },
  '21:9': { min: [511, 219], max: [3360, 1440] },
  '6:1': {
    min: [1020, 170],
    max: [4000, 666],
    [AdRatioTypes.IAB]: {
      min: [1024, 170],
    },
  },
  '16:9': { min: [512, 288], max: [2560, 1440] },
  '3:2': { min: [450, 300], max: [2160, 1440] },
  '4:3': { min: [512, 384], max: [4000, 3000] },
  '1.2:1': { min: [300, 250], max: [4000, 3333] },
  '9:40': { min: [234, 1040], max: [1440, 6400] },
  '1:3.75': { min: [160, 600], max: [1066, 4000] },
  '9:21': { min: [219, 511], max: [1440, 3360] },
  '1:2': { min: [300, 600], max: [2000, 4000] },
  '9:16': { min: [288, 512], max: [1440, 2560] },
  '2:3': {
    min: [320, 480],
    max: [2666, 4000],
    [AdRatioTypes.IAB]: {
      min: [341, 512],
    },
  },
  '3:4': { min: [384, 512], max: [1440, 1920] },
  '1:1': { min: [512, 512], max: [4000, 4000] },
}

export const VideoRatioResolutionMap = {
  '1:1': { min: [512, 512], max: [1080, 1080] },
  '4:3': { min: [512, 384], max: [1920, 1440] },
  '16:9': { min: [512, 288], max: [1920, 1080] },
  '9:16': { min: [288, 512], max: [1080, 1920] },
}

export const AdRatioTypesLabel = {
  DEPRECATING: 'Planned Deprecating Sizes',
  OTHER: 'Other Supported Programmatic Sizes',
}

export const RatioColloquialNameMap = {
  '728px by 90px': 'Leaderboard',
  '160px by 600px': 'Wide Skyscraper',
  '300px by 600px': 'Half Page Ad',
  '300px by 250px': 'Medium Rectangle',
  '320px by 50px': 'Mobile Banner 1',
  '320px by 480px': 'Mobile Interstitial Portrait',
  '512px by 384px': 'DOOH 22',
}

export const GAME_RELEASE_VALUES = {
  V_1: 'V1',
  V_1_PLUS: 'V1+',
}

export const INDUSTRY_VALUES = {
  B_B: 'B2B',
  AUTOMOTIVE: 'Automotive',
  CONSUMER_PACKAGED_GOODS: 'Consumer Packaged Goods',
  EDUCATION: 'Education',
  ENERGY_AND_UTILITIES: 'Energy and Utilities',
  ENTERTAINMENT: 'Entertainment',
  FINANCIAL_INSURANCE_SERVICES: 'Financial Services',
  GOVERNMENT_NON_PROFIT: 'Government Non-profit',
  HEALTHCARE: 'Healthcare',
  RETAIL: 'Retail',
  TECHNOLOGY: 'Technology',
  TELECOM: 'Telecom',
  TRAVEL_AND_HOSPITALITY: 'Travel and Hospitality',
  OTHER: 'Other',
}

export const ORG_VERIFY_STATUS = {
  UNKNOWN: 'UNKNOWN',
  UNVERIFIED: 'UNVERIFIED',
  REJECTED: 'REJECTED',
  UNDER_REVIEW: 'UNDER REVIEW',
  'UNDER REVIEW': 'UNDER_REVIEW',
  VERIFIED: 'VERIFIED',
  // ! statuses below not implemented yet
  SUSPENDED: 'SUSPENDED',
  BANNED: 'BANNED',
}

export const GAME_RATING_VALUES = {
  EVERYONE_TEN_PLUS: 'Everyone 10+',
}

export const STRIPE_API_URL = 'https://api.stripe.com/v1'

export const PAYMENT_ENUMS = toObject(PaymentTypeEnum)

export const GENDER = {
  ALL: 'all',
  MALE_ONLY: 'maleOnly',
  FEMALE_ONLY: 'femaleOnly',
}

const EARLY_CHILDHOOD = require('../assets/images/game-rating/esrb-ec.svg')
const EVERYONE = require('../assets/images/game-rating/esrb-e.svg')
const EVERYONE_TEN_PLUS = require('../assets/images/game-rating/esrb-e10.svg')
const TEEN = require('../assets/images/game-rating/esrb-t.svg')
const MATURE = require('../assets/images/game-rating/esrb-m.svg')
const ADULTS_ONLY = require('../assets/images/game-rating/esrb-ao.svg')
const RATING_PENDING = require('../assets/images/game-rating/esrb-rp.svg')

export const AD_CAMPAIGN_TYPE = toObject(CampaignTypeEnum)
export const AD_PURPOSE = toObject(AdPurposeEnum)
export const VERIFY_ITEM = toObject(VerifyItemEnum)
export const DEFAULT_ORDER_BY = toObject(DefaultOrderByEnum)
export const AD_STATUS = toObject(AdStatus)
export const AD_SPACE_STATUS = toObject(AdSpaceStatus)
export const GAME_STATUS = toObject(GameStatus)
export const REPORT_LAST_STATUS = toObject(ReportLastStatus)

export const AD_CAMPAIGN_LABEL = {
  [AD_CAMPAIGN_TYPE.STANDARD]: 'Standard',
  [AD_CAMPAIGN_TYPE.SPONSORSHIP]: 'Sponsorship',
}

export const THIRD_PARTY_TYPES = {
  AFFILIATE: 'Affiliate',
  AGGREGATOR: 'Aggregator',
  DATA_PROVIDER: 'Data provider',
  GAME_ENGINE: 'Game engine',
  OTHER: 'Other',
}

// ⚠️ Keep STRICTLY the SAME order as GameRatings
export const GAME_RATING_IMAGES = {
  EARLY_CHILDHOOD,
  EVERYONE,
  EVERYONE_TEN_PLUS,
  TEEN,
  MATURE,
  ADULTS_ONLY,
  RATING_PENDING,
}

export const GAME_AD_APPROVALS_TYPE = {
  FPSA: 'Frameplay',
  FPSA_GP: 'Frameplay & Studio',
  FRAMEPLAY: 'Frameplay',
  SUPER: 'Super',
  ADMIN: 'Admin',
}

export const BUSINESS_TYPES = {
  AD_SPACE: 'adSpace',
  ...VERIFY_ITEM,
}

export const BUSINESS_ACTIONS = {
  PAUSE: 'pause',
  ARCHIVE: 'archive',
  APPROVE: 'approve',
  REJECT: 'reject',
}

export const BID_TYPE = {
  COST_PER_IMPRESSION: 'CPI',
  COST_PER_ENGAGEMENT: 'CPE',
}

export const PACING = {
  EVEN: 'EVEN',
  ACCELERATED: 'ACCELERATED',
  ADDED_VALUE: 'ADDED_VALUE',
}

export const PACING_LABEL = {
  EVEN: 'Even',
  ACCELERATED: 'As fast as possible',
  ADDED_VALUE: 'Daily Goals Only',
}

export const AD_CONTENT_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video',
  VIDEO_WITH_SOUND: 'videoWithSound',
}

export const UPLOAD_FILE_TYPE = {
  IMAGE: '.jpg,.png,.jpeg',
  VIDEO: '.mp4',
}

export const UPLOAD_FILE_MAX_SIZE = {
  IMAGE: 2 * 1024, // size in kb
  VIDEO: 20 * 1024, // size in kb
}

export const VIDEO_FILE_DURATION = [6, 10, 15] // s

export const S3_MULTIPART_UPLOAD_CHUNK_SIZE = 50000 // kb, must be within range (min: 5Mb, max: 1Gb)

export const AD_CREATIVE_TYPE = 'AD_MEDIA'

export const MIME_TYPE = {
  JPEG: 'image/jpeg',
  PNG: 'image/png',
  MP4: 'video/mp4',
}

export const UI_STATUS = {
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  PENDING_REVIEW: 'PENDING_REVIEW',
  CONFIRMING: 'CONFIRMING',
  IDLE: 'IDLE',
}

export const STATUS_CODES = {
  AV100: 'Virus check in progress.',
  AV101: 'Virus check not started.',
  AV200: 'No virus found.',
  AV400: 'Virus found.',

  'Virus check in progress.': 'AV100',
  'Virus check not started.': 'AV101',
  'No virus found.': 'AV200',
  'Virus found.': 'AV400',
}

// Use 1 enum definition for the intervals
export const DATE_INTERVALS = toObject(DateIntervals)
export const DATE_INTERVAL_LABELS = {
  [DATE_INTERVALS.DAILY]: 'Last 24 Hours',
  [DATE_INTERVALS.WEEKLY]: 'Last 7 Days',
  [DATE_INTERVALS.MONTHLY]: 'Last 30 Days',
}

export const DASHBOARD_PERIOD_INTERVALS = toObject(DashboardPeriodIntervalsEnum)
export const DASHBOARD_PERIOD_INTERVAL_LABELS = {
  [DASHBOARD_PERIOD_INTERVALS.CUSTOM]: 'Custom',
  [DASHBOARD_PERIOD_INTERVALS.TODAY]: 'Today',
  [DASHBOARD_PERIOD_INTERVALS.LAST_3_DAYS]: 'Last 3 days',
  [DASHBOARD_PERIOD_INTERVALS.LAST_7_DAYS]: 'Last 7 days',
  [DASHBOARD_PERIOD_INTERVALS.LAST_14_DAYS]: 'Last 14 days',
  [DASHBOARD_PERIOD_INTERVALS.LAST_30_DAYS]: 'Last 30 days',
  [DASHBOARD_PERIOD_INTERVALS.LAST_3_MONTHS]: 'Last 3 months',
  [DASHBOARD_PERIOD_INTERVALS.LAST_6_MONTHS]: 'Last 6 months',
}

export const PERIOD_INTERVALS = toObject(PeriodIntervalsEnum)
export const PERIOD_INTERVAL_LABELS = {
  [PERIOD_INTERVALS.CUSTOM]: 'Custom',
  [PERIOD_INTERVALS.LAST_DAY]: 'Last day',
  [PERIOD_INTERVALS.LAST_7_DAYS]: 'Last 7 days',
  [PERIOD_INTERVALS.LAST_30_DAYS]: 'Last 30 days',
  [PERIOD_INTERVALS.LAST_2_MONTHS]: 'Last 2 months',
  [PERIOD_INTERVALS.LAST_3_MONTHS]: 'Last 3 months',
  [PERIOD_INTERVALS.LAST_4_MONTHS]: 'Last 4 months',
  [PERIOD_INTERVALS.LAST_5_MONTHS]: 'Last 5 months',
  [PERIOD_INTERVALS.LAST_6_MONTHS]: 'Last 6 months',
  [PERIOD_INTERVALS.LAST_7_MONTHS]: 'Last 7 months',
  [PERIOD_INTERVALS.LAST_8_MONTHS]: 'Last 8 months',
  [PERIOD_INTERVALS.LAST_9_MONTHS]: 'Last 9 months',
  [PERIOD_INTERVALS.LAST_10_MONTHS]: 'Last 10 months',
  [PERIOD_INTERVALS.LAST_11_MONTHS]: 'Last 11 months',
  [PERIOD_INTERVALS.LAST_12_MONTHS]: 'Last 12 months',
  [PERIOD_INTERVALS.LAST_13_MONTHS]: 'Last 13 months',
}

export const GAME_RATINGS = toObject(GameRatings)
export const GAME_RATINGS_LABELS = {
  [GAME_RATINGS.ANY]: 'Any',
  [GAME_RATINGS.EARLY_CHILDHOOD]: 'Early Childhood',
  [GAME_RATINGS.EVERYONE]: 'Everyone',
  [GAME_RATINGS.EVERYONE_TEN_PLUS]: 'Everyone 10 +',
  [GAME_RATINGS.TEEN]: 'Teen',
  [GAME_RATINGS.MATURE]: 'Mature',
  [GAME_RATINGS.ADULTS_ONLY]: 'Adults Only',
  [GAME_RATINGS.RATING_PENDING]: 'Rating Pending',
}

export const GAME_GENRES = toObject(GameGenres)
export const GAME_GENRES_LABELS = {
  [GAME_GENRES.ANY]: 'Any',
  [GAME_GENRES.ACTION]: 'Action',
  [GAME_GENRES.ADVENTURE]: 'Adventure',
  [GAME_GENRES.CASUAL]: 'Casual',
  [GAME_GENRES.INDIE]: 'Indie',
  [GAME_GENRES.RACING]: 'Racing',
  [GAME_GENRES.ROLE_PLAYING]: 'Role Playing',
  [GAME_GENRES.SIMULATION]: 'Simulation',
  [GAME_GENRES.STRATEGY]: 'Strategy',
  [GAME_GENRES.SPORTS]: 'Sports',
  [GAME_GENRES.SHOOTER]: 'Shooter',
}

export const TIMEZONE_TYPES = toObject(ReportingTimezones)
export const TIMEZONES = [{ text: 'UTC', value: TIMEZONE_TYPES.UTC }]

export const DEFAULT_DATE_FORMAT = 'dd MMM, yyyy'

export const TITLE_TYPES = toObject(TitleTypeEnum)

// use this for mocking one client select item to test editing modes.
// when we load the ad form in edit mode, we want to load the same select item
// between getAds and getClients
export const MOCK_CLIENT_SELECT_ITEM = {
  id: 'demoClient',
  title: 'Demo Client',
}

export const STUDENT_SELECT_ITEMS = [
  {
    value: { prop: null },
    text: 'All',
  },
  {
    value: { prop: true },
    text: 'Only Students',
  },
  {
    // Value must be string|numnber|object
    value: { prop: false },
    text: 'Only Businesses',
  },
]

// required as root dom element for some vuetify systems to work,
// e.g. class helpers, pt-2, text-align, etc
// some components used in this app are outside of the v-application dom such as fp-popups
export const VUETIFY_APP_CLASS = 'v-application'

export const SDK_DOWNLOAD_ORIGINS = {
  FRAMEPLAY: 'Frameplay',
  SKIPPED: 'Skipped',
  UNITY: 'Unity',
  UNKNOWN: 'Unknown',
}

export const CHIP_TYPES = {
  LIGHT: 'light',
  DARK: 'dark',
  PRIMARY: 'primary',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  ACCENT: 'accent',
  DISABLED: 'disabled',
  INACTIVE: 'inactive',
}

export const RoleTypeEnum = {
  ACCOUNT_MANAGER: 'ACCOUNT_MANAGER',
  ADMIN: 'ADMIN',
  DEVELOPER: 'DEVELOPER',
  FINANCE: 'FINANCE',
  FULL_ACCESS: 'FULL_ACCESS',
  OWNER: 'OWNER',
  REPORTING: 'REPORTING',
}

export const PredefinedRoleIdEnum = {
  ACCOUNT_MANAGER: 'GAME_PUBLISHER:ACCOUNT_MANAGER',
  ADMIN: 'GAME_PUBLISHER:ADMIN',
  DEVELOPER: 'GAME_PUBLISHER:DEVELOPER',
  FINANCE: 'GAME_PUBLISHER:FINANCE',
  FULL_ACCESS: 'GAME_PUBLISHER:FULL_ACCESS',
  OWNER: 'GAME_PUBLISHER:OWNER',
  REPORTING: 'GAME_PUBLISHER:REPORTING',
}

export const DISPLAYED_ROLES = Object.values(RoleTypeEnum).map(i => humanize(i))

export const EXPORT_FILE_DATE_FORMAT = 'yyyyMMdd'
export const EXPORT_NO_DATA_MESSAGE = 'No data available for display.'

export const SELECTABLE_ROLES = Object.keys(RoleTypeEnum).map(i => {
  return {
    label: humanize(i),
    value: i,
  }
})

export const OWNER_TIP =
  'This is an organization owner. An organization owner cannot be assigned any other role.'

export const METRIC_TIP_DEMAND = `
<p class="text-left"> The following metrics are available. </p>
<div class="text-left">
<strong class="primary--text">
  Impressions
</strong>
<p>
  An ad was served to an Ad Space.
</p>

<strong class="primary--text">
  Viewable Impressions
</strong>
<p>
  An ad was served to an Ad Space and has been identified as viewable.
</p>

<p>
  The viewability of an impression is a proprietary calculation, devised by Frameplay, that uses a number of factors from the players perspective including; Time on Screen, Screen Percentage, Ad Space Percentage, Orientation, and Ratio Skew.
</p>

<strong class="primary--text">
  Spend
</strong>
<p>
  Amount of money an advertiser has spent on Viewable Impressions of an Ad.
</p>

<strong class="primary--text">
  Unique Users
</strong>

<p>
  Distinct count of unique users associated with a game, per day, based on IP + Universal Unique Identifier (UUID) and where available the Identifier For Advertisers (IDFA) / MAID (Mobile Advertising ID (MAID), device platform (iOS/Android/PC/etc) or device model (iPhone 12/iPad 4/etc.).
</p>

<p>
  Unique Users are currently aggregated daily across these supplied dimensions. Therefore Unique User count does not persist across multiple days. This should be considered when additional aggregations are requested and applied.
</p>

<p>
  Device IP address may refresh every 24 hours to 14 days depending on the user device IP Address Settings.
</p>
</div>`

export const METRIC_TIP_SUPPLY = `
<p class="text-left"> The following metrics are available. </p>
<div class="text-left">
<strong class="primary--text">
  Impressions
</strong>
<p>
  An ad was served into an Ad Space.
</p>

<strong class="primary--text">
Viewable Impressions
</strong>
<p>
  An ad was served into an Ad Space and has been identified as viewable.
</p>

<p>
  The viewability of an impression is a proprietary calculation, created by Frameplay, that uses a number of factors from the player’s perspective including: Time on Screen, Screen Percentage, Ad Space Percentage, Orientation, and Ratio Skew.
</p>

<strong class="primary--text">
  Unique Users
</strong>

<p>
  Distinct count of unique users associated with a game, day, based on IP + Universal Unique Identifier (UUID) and, where available, Identifier For Advertisers (IDFA) / Mobile Advertising ID (MAID), device platform (iOS/Android/PC/etc) or device model (iPhone 12/iPad 4/etc.).
</p>

<p>
  Unique Users are currently aggregated across these supplied dimensions daily. Therefore, Unique User count does not persist across multiple days. This should be considered when additional aggregations are requested and applied.
</p>

<p>
  Device IP address may refresh every 24 hours to 14 days depending on the user device settings.
</p>

<strong class="primary--text">
Revenue
</strong>
<p>
  Advertising spend distributed to a game.
</p>

<p>
  In this report revenue will be similar to–but may not exactly align–to payment distribution due to varying rounding methodologies. Please use this metric directionally for optimization purposes, however, final revenue will continue to be supplied by Frameplay Finance Reporting.
</p>
</div>`

export const METRIC_TIP = {
  METRIC_TIP_SUPPLY,
  METRIC_TIP_DEMAND,
}

export const DIMENSION_TIP_DEMAND = `
<p class="text-left"> The following dimensions will be available depending on the selected metrics. </p>
<div class="text-left">
<strong class="primary--text">
  Advertisement Name
</strong>
<p>
  The name of the Campaign as entered by the Advertiser.
</p>

<strong class="primary--text">
  Client Name
</strong>
<p>
  The name of the Client as entered by the Advertiser.
</p>

<strong class="primary--text">
  Country
</strong>
<p>
  The Country where the user’s device IP address is located.
</p>

<strong class="primary--text">
  Player Region
</strong>
<p>
  The State or Providence where the user's device IP address is located.
</p>

<strong class="primary--text">
  Day
</strong>
<p>
  The date in which the metric was captured, based on player activity.
</p>

<strong class="primary--text">
  Platform
</strong>
<p>
  The user's device Operating System aggregated into a Platform metric by Frameplay as either Android, IOS, OSX, Windows, etc.
</p>

<strong class="primary--text">
  Game Rating
</strong>
<p>
  The ESRB rating of the game where the advertisement was displayed.
</p>

<strong class="primary--text">
  Game Genre
</strong>
<p>
  The genre of the game where the advertisement was displayed.
</p>
</div>`

export const DIMENSION_TIP_SUPPLY = `
<p class="text-left"> The following dimensions will be available depending on the selected metrics. </p>
<div class="text-left">

<strong class="primary--text">
  Day
</strong>
<p>
  The date in which the metric was captured, based on player activity.
</p>

<strong class="primary--text">
  Country
</strong>
<p>
  The Country where the user’s device IP address is located.
</p>

<strong class="primary--text">
  Player Region
</strong>
<p>
  The State or Providence where the user's device IP address is located.
</p>

<strong class="primary--text">
  Game Publisher
</strong>
<p>
  The name of the Game Publisher.
</p>

<strong class="primary--text">
  Game Title
</strong>
<p>
  The Game Title as entered by the Game Publisher.
</p>

<strong class="primary--text">
  Platform
</strong>
<p>
  The user’s device Operating System aggregated into a Platform metric as either Android, IOS, OSX, Windows, etc
</p>

<strong class="primary--text">
  Ad Space Ratio
</strong>
<p>
  The Aspect Ratio of the Ad Space placement. Standard ratios include; 60:90, 9:21, 21:9, 40:9, 2:3, 16:9, 9:40, 9:16, 1:1, 4:3, 3:2, and 3:4.
</p>

<strong class="primary--text">
  Ad Space Name
</strong>
<p>
  The Name assigned to the Ad Space placement by the Game Publisher.
</p>

<strong class="primary--text">
  Ad Space ID
</strong>
<p>
  The Identifier assigned to an Ad Space by the Frameplay.
</p>

</div>`

export const DIMENSION_TIP = {
  DIMENSION_TIP_DEMAND,
  DIMENSION_TIP_SUPPLY,
}

export const CHART_LABELS = {
  UNIQUE_USERS: 'Unique Users',
  VIEWS: 'Viewable Impressions',
  REACH: 'Unique Users',
  EARNINGS: 'Earnings',
  REVENUE: 'Revenue',
  SPEND: 'Spend',
  UNKNOWN: 'Unknown',
  UNDISCLOSED: 'UNDISCLOSED',
  ASSET: 'Asset',
  ADVERTISEMENTS: 'Advertisements',
  BUDGET: 'Budget',
  COUNTRY: 'Country',
  GENRE: 'Genre',
  GAMES: 'Games',
  RATING: 'Rating',
  FEMALE: 'Female',
  MALE: 'Male',
  TOP_STATS: 'Top Stats',
  GENDER_DEMOGRAPHIC: 'Gender Demographic',
  GENDER_SPLIT: 'Gender Split',
  PLATFORM_DEMOGRAPHIC: 'Platform Demographic',
  AGE_DEMOGRAPHIC: 'Age Demographic',
  AGE_SPLIT: 'Age Split',
  EARLY_CHILDHOOD: 'EC',
  EVERYONE: 'E',
  EVERYONE_TEN_PLUS: 'E10+',
  MATURE: 'M',
  ADULTS_ONLY: 'AO',
  RATING_PENDING: 'RP',
  ANY: 'ANY',
  TEEN: 'T',
}

export const AD_RESTRICTIONS_LABELS = {
  // TODO: remove old restrictions when BE migration is done
  ALCOHOL_REFERENCES: 'Alcohol references',
  BLOOD_REFERENCES: 'Blood references',
  CARTOON_VIOLENCE: 'Cartoon violence',
  DRUG_REFERENCES: 'Drug references',
  ETHICAL_REFERENCES: 'Ethical references',
  GAMBLING: 'Gambling',
  NUDITY: 'Nudity',
  POLITICAL_REFERENCES: 'Political references',
  RELIGIOUS_REFERENCES: 'Religious references',
  SEXUAL_CONTENT: 'Sexual content',
  STRONG_LANGUAGE: 'Strong language',
  TOBACCO_REFERENCES: 'Tobacco references',
  VIOLENCE: 'Violence',
  WEAPON_REFERENCES: 'Weapon references',
  ALCOHOL: 'Alcohol',
  GAMBLING_STATE_LOTTERY: 'Gambling > State lottery',
  GAMBLING_SPORTS: 'Gambling > Sports gambling',
  MEDICAL_PHARMA_OC: 'Medical & Pharmaceuticals > Over the counter',
  MEDICAL_PHARMA_PD: 'Medical & Pharmaceuticals > Prescription drugs',
  MEDICAL_PHARMA_HC_PSA: 'Medical & Pharmaceuticals > Health campaigns/PSAs',
  POLITICS_FEDERAL_ELECTIONS: 'Politics > Federal elections/politics',
  POLITICS_STATE_LOCAL_ELECTIONS: 'Politics > State & local elections/politics',
}

export const CHART_KEYS = {
  UNIQUE_USERS: 'uniqueUsers',
  VIEWS: 'viewableImpressions',
  REACH: 'reach',
  MONEY: 'money',
  EARNINGS: 'earnings',
  UNKNOWN: 'UNKNOWN',
  TOP: 'top',
  COUNTRY: 'country',
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
}

export const DEFAULT_FILTER_VALUE = 'ALL'

export const REPORT_PLATFORM_TYPES = toObject(ReportPlatformEnum)
export const REPORT_PLATFORMS = [
  { text: 'All Platforms', value: 'ALL' },
  { text: 'iOS', value: REPORT_PLATFORM_TYPES.IOS },
  { text: 'macOS', value: REPORT_PLATFORM_TYPES.OSX },
  { text: 'Android', value: REPORT_PLATFORM_TYPES.ANDROID },
  { text: 'Windows', value: REPORT_PLATFORM_TYPES.WINDOWS },
  { text: 'Unknown', value: REPORT_PLATFORM_TYPES.UNKNOWN },
]
export const PLATFORMS = [
  { text: 'All Platforms', value: 'all' },
  { text: 'Console', value: 'console' },
  { text: 'Mobile', value: 'mobile' },
  { text: 'PC', value: 'pc' },
]

export const METRICS = MetricEnum

export const ALL_METRICS = [
  { text: 'Impressions', value: METRICS.IMPRESSION_COUNT },
  { text: 'Impression ECPM', value: METRICS.IMPRESSION_ECPM },
  { text: 'Viewable Impressions', value: METRICS.VIEWABLE_IMPRESSION_COUNT },
  { text: 'Viewable Impressions CPM', value: METRICS.VIEWABLE_IMPRESSION_CPM },
  { text: 'Revenue', value: METRICS.REVENUE },
  { text: 'Share Of Voice', value: METRICS.SHARE_OF_VOICE },
  { text: 'Spend', value: METRICS.SPEND },
  { text: 'Unique Users', value: METRICS.UNIQUE_USERS },
  { text: 'Intrinsic Time In View Minutes', value: METRICS.ITIV_MINUTES },
  { text: 'Budget Delivered', value: METRICS.BUDGET_DELIVERED },
]

export const REPORTING_METRICS_BY_USERTYPE = {
  SUPPLY: [
    { text: 'Impressions', value: METRICS.IMPRESSION_COUNT },
    { text: 'Viewable Impressions', value: METRICS.VIEWABLE_IMPRESSION_COUNT },
    { text: 'Revenue', value: METRICS.REVENUE },
    { text: 'Intrinsic Time In View Minutes', value: METRICS.ITIV_MINUTES },
  ],
  DEMAND: [
    { text: 'Impressions', value: METRICS.IMPRESSION_COUNT },
    { text: 'Viewable Impressions', value: METRICS.VIEWABLE_IMPRESSION_COUNT },
    // { text: 'Share Of Voice', value: METRICS.SHARE_OF_VOICE },
    { text: 'Spend', value: METRICS.SPEND },
    { text: 'Intrinsic Time In View Minutes', value: METRICS.ITIV_MINUTES },
  ],
}

export const CUSTOMERS = toObject(CustomerTypeEnum)

export const CUSTOMER_TYPES = [
  { text: 'Demand', value: CUSTOMERS.DEMAND },
  { text: 'Supply', value: CUSTOMERS.SUPPLY },
]

export const METRICS_BY_USERTYPE = {
  GamePublisher: [
    { text: 'Impressions', value: METRICS.IMPRESSION_COUNT },
    { text: 'Viewable Impressions', value: METRICS.VIEWABLE_IMPRESSION_COUNT },
    { text: 'Revenue', value: METRICS.REVENUE },
    { text: 'Unique Users', value: METRICS.UNIQUE_USERS },
  ],
  Demand: [
    { text: 'Impressions', value: METRICS.IMPRESSION_COUNT },
    { text: 'Viewable Impressions', value: METRICS.VIEWABLE_IMPRESSION_COUNT },
    { text: 'Spend', value: METRICS.SPEND },
    { text: 'Unique Users', value: METRICS.UNIQUE_USERS },
  ],
  FPSA: [
    { text: 'Impressions', value: METRICS.IMPRESSION_COUNT },
    { text: 'Viewable Impressions', value: METRICS.VIEWABLE_IMPRESSION_COUNT },
    { text: 'Spend', value: METRICS.SPEND },
    { text: 'Revenue', value: METRICS.REVENUE },
    { text: 'Unique Users', value: METRICS.UNIQUE_USERS },
  ],
}

export const DIMENSIONS = DimensionEnum

export const ALL_DIMENSIONS = [
  { text: 'Ad ID', value: DIMENSIONS.AD_ID },
  { text: 'Ad Name', value: DIMENSIONS.AD_NAME },
  { text: 'Ad Space ID', value: DIMENSIONS.AD_SPACE_ID },
  { text: 'Ad Space Name', value: DIMENSIONS.AD_SPACE_DESCRIPTION },
  { text: 'Ad Space Ratio', value: DIMENSIONS.AD_SPACE_RATIO },
  { text: 'Advertisement Name', value: DIMENSIONS.AD_NAME },
  { text: 'Campaign Type', value: DIMENSIONS.CAMPAIGN_TYPE },
  { text: 'Client Name', value: DIMENSIONS.CLIENT_NAME },
  { text: 'Country', value: DIMENSIONS.COUNTRY },
  { text: 'Creative Size', value: DIMENSIONS.CREATIVE_SIZE },
  { text: 'Currency Code', value: DIMENSIONS.CURRENCY_CODE },
  { text: 'Day', value: DIMENSIONS.DAY },
  { text: 'Frequency', value: DIMENSIONS.FREQUENCY },
  { text: 'Game Rating', value: DIMENSIONS.ESRB_RATING },
  { text: 'Game Title', value: DIMENSIONS.GAME_TITLE },
  { text: 'Genre', value: DIMENSIONS.GENRE },
  { text: 'Month', value: DIMENSIONS.MONTH },
  { text: 'Organization', value: DIMENSIONS.ORGANIZATION },
  { text: 'Platform', value: DIMENSIONS.PLATFORM },
  { text: 'Player Region', value: DIMENSIONS.PLAYER_REGION },
  { text: 'Postcode', value: DIMENSIONS.POSTCODE },
  { text: 'Ad Status', value: DIMENSIONS.AD_STATUS },
  { text: 'Budget Goal', value: DIMENSIONS.BUDGET_GOAL },
  { text: 'Start Date', value: DIMENSIONS.START_DATE },
  { text: 'End Date', value: DIMENSIONS.END_DATE },
  { text: 'Deal ID', value: DIMENSIONS.DEAL_ID },
]

export const DIMENSIONS_BY_METRIC = {
  [METRICS.IMPRESSION_COUNT]: [
    DIMENSIONS.DAY,
    DIMENSIONS.COUNTRY,
    DIMENSIONS.MONTH,
    DIMENSIONS.ORGANIZATION,
    DIMENSIONS.GAME_TITLE,
    DIMENSIONS.PLATFORM,
    DIMENSIONS.AD_SPACE_RATIO,
    DIMENSIONS.AD_SPACE_ID,
    DIMENSIONS.AD_SPACE_DESCRIPTION,
    DIMENSIONS.PLAYER_REGION,
    DIMENSIONS.AD_NAME,
    DIMENSIONS.CLIENT_NAME,
    DIMENSIONS.ESRB_RATING,
    DIMENSIONS.CREATIVE_SIZE,
    DIMENSIONS.CURRENCY_CODE,
    DIMENSIONS.FREQUENCY,
    DIMENSIONS.GENRE,
    DIMENSIONS.AD_STATUS,
    DIMENSIONS.BUDGET_GOAL,
    DIMENSIONS.START_DATE,
    DIMENSIONS.END_DATE,
    DIMENSIONS.DEAL_ID,
    DIMENSIONS.POSTCODE,
  ],
  [METRICS.IMPRESSION_ECPM]: [
    DIMENSIONS.DAY,
    DIMENSIONS.COUNTRY,
    DIMENSIONS.MONTH,
    DIMENSIONS.ORGANIZATION,
    DIMENSIONS.GAME_TITLE,
    DIMENSIONS.PLATFORM,
    DIMENSIONS.AD_SPACE_RATIO,
    DIMENSIONS.AD_SPACE_ID,
    DIMENSIONS.AD_SPACE_DESCRIPTION,
    DIMENSIONS.PLAYER_REGION,
    DIMENSIONS.AD_NAME,
    DIMENSIONS.CLIENT_NAME,
    DIMENSIONS.ESRB_RATING,
    DIMENSIONS.CREATIVE_SIZE,
    DIMENSIONS.CURRENCY_CODE,
    DIMENSIONS.FREQUENCY,
    DIMENSIONS.GENRE,
    DIMENSIONS.AD_STATUS,
    DIMENSIONS.BUDGET_GOAL,
    DIMENSIONS.START_DATE,
    DIMENSIONS.END_DATE,
    DIMENSIONS.DEAL_ID,
    DIMENSIONS.POSTCODE,
  ],
  [METRICS.VIEWABLE_IMPRESSION_COUNT]: [
    DIMENSIONS.DAY,
    DIMENSIONS.COUNTRY,
    DIMENSIONS.MONTH,
    DIMENSIONS.ORGANIZATION,
    DIMENSIONS.GAME_TITLE,
    DIMENSIONS.PLATFORM,
    DIMENSIONS.AD_SPACE_RATIO,
    DIMENSIONS.AD_SPACE_ID,
    DIMENSIONS.AD_SPACE_DESCRIPTION,
    DIMENSIONS.PLAYER_REGION,
    DIMENSIONS.AD_NAME,
    DIMENSIONS.CLIENT_NAME,
    DIMENSIONS.ESRB_RATING,
    DIMENSIONS.CREATIVE_SIZE,
    DIMENSIONS.CURRENCY_CODE,
    DIMENSIONS.FREQUENCY,
    DIMENSIONS.GENRE,
    DIMENSIONS.AD_STATUS,
    DIMENSIONS.BUDGET_GOAL,
    DIMENSIONS.START_DATE,
    DIMENSIONS.END_DATE,
    DIMENSIONS.DEAL_ID,
    DIMENSIONS.POSTCODE,
  ],
  [METRICS.IMPRESSION_ECPM]: [
    DIMENSIONS.DAY,
    DIMENSIONS.COUNTRY,
    DIMENSIONS.MONTH,
    DIMENSIONS.ORGANIZATION,
    DIMENSIONS.GAME_TITLE,
    DIMENSIONS.PLATFORM,
    DIMENSIONS.AD_SPACE_RATIO,
    DIMENSIONS.AD_SPACE_ID,
    DIMENSIONS.AD_SPACE_DESCRIPTION,
    DIMENSIONS.PLAYER_REGION,
    DIMENSIONS.AD_NAME,
    DIMENSIONS.CLIENT_NAME,
    DIMENSIONS.ESRB_RATING,
    DIMENSIONS.CREATIVE_SIZE,
    DIMENSIONS.CURRENCY_CODE,
    DIMENSIONS.FREQUENCY,
    DIMENSIONS.GENRE,
    DIMENSIONS.AD_STATUS,
    DIMENSIONS.BUDGET_GOAL,
    DIMENSIONS.START_DATE,
    DIMENSIONS.END_DATE,
    DIMENSIONS.DEAL_ID,
    DIMENSIONS.POSTCODE,
  ],
  [METRICS.REVENUE]: [
    DIMENSIONS.DAY,
    DIMENSIONS.COUNTRY,
    DIMENSIONS.MONTH,
    DIMENSIONS.ORGANIZATION,
    DIMENSIONS.GAME_TITLE,
    DIMENSIONS.PLATFORM,
    DIMENSIONS.AD_SPACE_RATIO,
    DIMENSIONS.AD_SPACE_ID,
    DIMENSIONS.AD_SPACE_DESCRIPTION,
    DIMENSIONS.PLAYER_REGION,
    DIMENSIONS.CREATIVE_SIZE,
    DIMENSIONS.CURRENCY_CODE,
    DIMENSIONS.FREQUENCY,
    DIMENSIONS.GENRE,
    DIMENSIONS.POSTCODE,
  ],
  [METRICS.UNIQUE_USERS]: [
    DIMENSIONS.DAY,
    DIMENSIONS.COUNTRY,
    DIMENSIONS.ORGANIZATION,
    DIMENSIONS.GAME_TITLE,
    DIMENSIONS.PLATFORM,
    DIMENSIONS.AD_NAME,
    DIMENSIONS.CLIENT_NAME,
    DIMENSIONS.ESRB_RATING,
  ],
  [METRICS.SPEND]: [
    DIMENSIONS.ESRB_RATING,
    DIMENSIONS.COUNTRY,
    DIMENSIONS.AD_NAME,
    DIMENSIONS.MONTH,
    DIMENSIONS.ORGANIZATION,
    DIMENSIONS.CLIENT_NAME,
    DIMENSIONS.PLATFORM,
    DIMENSIONS.DAY,
    DIMENSIONS.CREATIVE_SIZE,
    DIMENSIONS.CURRENCY_CODE,
    DIMENSIONS.FREQUENCY,
    DIMENSIONS.GENRE,
    DIMENSIONS.AD_STATUS,
    DIMENSIONS.BUDGET_GOAL,
    DIMENSIONS.START_DATE,
    DIMENSIONS.END_DATE,
    DIMENSIONS.DEAL_ID,
    DIMENSIONS.POSTCODE,
  ],
  [METRICS.ITIV_MINUTES]: [
    DIMENSIONS.DAY,
    DIMENSIONS.COUNTRY,
    DIMENSIONS.MONTH,
    DIMENSIONS.ORGANIZATION,
    DIMENSIONS.GAME_TITLE,
    DIMENSIONS.PLATFORM,
    DIMENSIONS.AD_SPACE_RATIO,
    DIMENSIONS.AD_SPACE_ID,
    DIMENSIONS.AD_SPACE_DESCRIPTION,
    DIMENSIONS.PLAYER_REGION,
    DIMENSIONS.AD_NAME,
    DIMENSIONS.CLIENT_NAME,
    DIMENSIONS.ESRB_RATING,
    DIMENSIONS.CREATIVE_SIZE,
    DIMENSIONS.CURRENCY_CODE,
    DIMENSIONS.FREQUENCY,
    DIMENSIONS.GENRE,
    DIMENSIONS.AD_STATUS,
    DIMENSIONS.BUDGET_GOAL,
    DIMENSIONS.START_DATE,
    DIMENSIONS.END_DATE,
    DIMENSIONS.DEAL_ID,
    DIMENSIONS.POSTCODE,
  ],
  [METRICS.SHARE_OF_VOICE]: [
    DIMENSIONS.DAY,
    DIMENSIONS.COUNTRY,
    DIMENSIONS.MONTH,
    DIMENSIONS.ORGANIZATION,
    DIMENSIONS.GAME_TITLE,
    DIMENSIONS.PLATFORM,
    DIMENSIONS.AD_SPACE_RATIO,
    DIMENSIONS.AD_SPACE_ID,
    DIMENSIONS.AD_SPACE_DESCRIPTION,
    DIMENSIONS.PLAYER_REGION,
    DIMENSIONS.AD_NAME,
    DIMENSIONS.CLIENT_NAME,
    DIMENSIONS.ESRB_RATING,
    DIMENSIONS.CREATIVE_SIZE,
    DIMENSIONS.CURRENCY_CODE,
    DIMENSIONS.FREQUENCY,
    DIMENSIONS.GENRE,
    DIMENSIONS.AD_STATUS,
    DIMENSIONS.BUDGET_GOAL,
    DIMENSIONS.START_DATE,
    DIMENSIONS.END_DATE,
    DIMENSIONS.DEAL_ID,
    DIMENSIONS.POSTCODE,
  ],
}

export const REPORTING_DIMENSIONS_BY_USERTYPE = {
  ADVERTISER: [
    { text: 'Advertisement Name', value: DIMENSIONS.AD_NAME },
    { text: 'Campaign Type', value: DIMENSIONS.CAMPAIGN_TYPE },
    { text: 'Creative Size', value: DIMENSIONS.CREATIVE_SIZE },
    { text: 'Country', value: DIMENSIONS.COUNTRY },
    { text: 'Game Rating', value: DIMENSIONS.ESRB_RATING },
    { text: 'Platform', value: DIMENSIONS.PLATFORM },
    { text: 'Player Region', value: DIMENSIONS.PLAYER_REGION },
  ],
  AGENCY: [
    { text: 'Advertisement Name', value: DIMENSIONS.AD_NAME },
    { text: 'Campaign Type', value: DIMENSIONS.CAMPAIGN_TYPE },
    { text: 'Client Name', value: DIMENSIONS.CLIENT_NAME },
    { text: 'Country', value: DIMENSIONS.COUNTRY },
    { text: 'Creative Size', value: DIMENSIONS.CREATIVE_SIZE },
    { text: 'Game Rating', value: DIMENSIONS.ESRB_RATING },
    { text: 'Platform', value: DIMENSIONS.PLATFORM },
    { text: 'Player Region', value: DIMENSIONS.PLAYER_REGION },
  ],
  GAME_PUBLISHER: [
    { text: 'Country', value: DIMENSIONS.COUNTRY },
    { text: 'Game Title', value: DIMENSIONS.GAME_TITLE },
    { text: 'Platform', value: DIMENSIONS.PLATFORM },
    { text: 'Player Region', value: DIMENSIONS.PLAYER_REGION },
  ],
  FPSA: [
    { text: 'Advertisement Name', value: DIMENSIONS.AD_NAME },
    { text: 'Campaign Type', value: DIMENSIONS.CAMPAIGN_TYPE },
    { text: 'Client Name', value: DIMENSIONS.CLIENT_NAME },
    { text: 'Country', value: DIMENSIONS.COUNTRY },
    { text: 'Creative Size', value: DIMENSIONS.CREATIVE_SIZE },
    { text: 'Game Rating', value: DIMENSIONS.ESRB_RATING },
    { text: 'Game Title', value: DIMENSIONS.GAME_TITLE },
    { text: 'Organization', value: DIMENSIONS.ORGANIZATION },
    { text: 'Platform', value: DIMENSIONS.PLATFORM },
    { text: 'Player Region', value: DIMENSIONS.PLAYER_REGION },
  ],
}

export const DIMENSIONS_BY_USERTYPE = {
  AGENCY: [
    { text: 'Game Rating', value: DIMENSIONS.ESRB_RATING },
    { text: 'Country', value: DIMENSIONS.COUNTRY },
    { text: 'Advertisement Name', value: DIMENSIONS.AD_NAME },
    { text: 'Campaign Type', value: DIMENSIONS.CAMPAIGN_TYPE },
    { text: 'Client Name', value: DIMENSIONS.CLIENT_NAME },
    { text: 'Platform', value: DIMENSIONS.PLATFORM },
    { text: 'Day', value: DIMENSIONS.DAY },
    { text: 'Month', value: DIMENSIONS.MONTH },
    { text: 'Organization', value: DIMENSIONS.ORGANIZATION },
  ],
  ADVERTISER: [
    { text: 'Game Rating', value: DIMENSIONS.ESRB_RATING },
    { text: 'Campaign Type', value: DIMENSIONS.CAMPAIGN_TYPE },
    { text: 'Country', value: DIMENSIONS.COUNTRY },
    { text: 'Advertisement Name', value: DIMENSIONS.AD_NAME },
    { text: 'Platform', value: DIMENSIONS.PLATFORM },
    { text: 'Day', value: DIMENSIONS.DAY },
    { text: 'Month', value: DIMENSIONS.MONTH },
    { text: 'Organization', value: DIMENSIONS.ORGANIZATION },
  ],
  GAME_PUBLISHER: [
    { text: 'Country', value: DIMENSIONS.COUNTRY },
    { text: 'Day', value: DIMENSIONS.DAY },
    { text: 'Month', value: DIMENSIONS.MONTH },
    { text: 'Organization', value: DIMENSIONS.ORGANIZATION },
    { text: 'Game Title', value: DIMENSIONS.GAME_TITLE },
    { text: 'Platform', value: DIMENSIONS.PLATFORM },
    { text: 'Player Region', value: DIMENSIONS.PLAYER_REGION },
    { text: 'Ad Space ID', value: DIMENSIONS.AD_SPACE_ID },
    { text: 'Ad Space Name', value: DIMENSIONS.AD_SPACE_DESCRIPTION },
    { text: 'Ad Space Ratio', value: DIMENSIONS.AD_SPACE_RATIO },
  ],
  FPSA: [
    { text: 'Advertisement Name', value: DIMENSIONS.AD_NAME },
    { text: 'Campaign Type', value: DIMENSIONS.CAMPAIGN_TYPE },
    { text: 'Client Name', value: DIMENSIONS.CLIENT_NAME },
    { text: 'Country', value: DIMENSIONS.COUNTRY },
    { text: 'Day', value: DIMENSIONS.DAY },
    { text: 'Month', value: DIMENSIONS.MONTH },
    { text: 'Organization', value: DIMENSIONS.ORGANIZATION },
    { text: 'Game Rating', value: DIMENSIONS.ESRB_RATING },
    { text: 'Game Title', value: DIMENSIONS.GAME_TITLE },
    { text: 'Platform', value: DIMENSIONS.PLATFORM },
    { text: 'Player Region', value: DIMENSIONS.PLAYER_REGION },
    { text: 'Ad Space ID', value: DIMENSIONS.AD_SPACE_ID },
    { text: 'Ad Space Name', value: DIMENSIONS.AD_SPACE_DESCRIPTION },
    { text: 'Ad Space Ratio', value: DIMENSIONS.AD_SPACE_RATIO },
    { text: 'Creative Size', value: DIMENSIONS.CREATIVE_SIZE },
  ],
}

export const PERIOD_TYPES = toObject(PeriodTypeEnum)
export const PERIODS = [
  { text: 'Custom', value: PERIOD_TYPES.CUSTOM },
  { text: 'Last Day', value: PERIOD_TYPES.LAST_DAY },
  { text: 'Last 7 Days', value: PERIOD_TYPES.LAST_7_DAYS },
  { text: 'Last 30 Days', value: PERIOD_TYPES.LAST_30_DAYS },
  { text: 'Last 2 Months', value: PERIOD_TYPES.LAST_2_MONTHS },
  { text: 'Last 3 Months', value: PERIOD_TYPES.LAST_3_MONTHS },
  { text: 'Last 4 Months', value: PERIOD_TYPES.LAST_4_MONTHS },
  { text: 'Last 5 Months', value: PERIOD_TYPES.LAST_5_MONTHS },
  { text: 'Last 6 Months', value: PERIOD_TYPES.LAST_6_MONTHS },
  { text: 'Last 7 Months', value: PERIOD_TYPES.LAST_7_MONTHS },
  { text: 'Last 8 Months', value: PERIOD_TYPES.LAST_8_MONTHS },
  { text: 'Last 9 Months', value: PERIOD_TYPES.LAST_9_MONTHS },
  { text: 'Last 10 Months', value: PERIOD_TYPES.LAST_10_MONTHS },
  { text: 'Last 11 Months', value: PERIOD_TYPES.LAST_11_MONTHS },
  { text: 'Last 12 Months', value: PERIOD_TYPES.LAST_12_MONTHS },
  { text: 'Last 13 Months', value: PERIOD_TYPES.LAST_13_MONTHS },
]

export const RECURRENCE = toObject(RecurrenceTypeEnum)
export const REPORT_SCHEDULE = [
  { text: `On demand`, value: RECURRENCE.NO_REPEAT },
  { text: 'Daily', value: RECURRENCE.DAILY },
  { text: 'Weekly', value: RECURRENCE.WEEKLY },
  { text: 'Monthly on the first', value: RECURRENCE.MONTHLY_FIRST },
  { text: 'Monthly on the last', value: RECURRENCE.MONTHLY_LAST },
]
export const WEEK_DAYS = toObject(DayOfTheWeekEnum)
export const SCHEDULE_DAYS = [
  { text: 'Monday', value: WEEK_DAYS.MONDAY },
  { text: 'Tuesday', value: WEEK_DAYS.TUESDAY },
  { text: 'Wednesday', value: WEEK_DAYS.WEDNESDAY },
  { text: 'Thursday', value: WEEK_DAYS.THURSDAY },
  { text: 'Friday', value: WEEK_DAYS.FRIDAY },
  { text: 'Saturday', value: WEEK_DAYS.SATURDAY },
  { text: 'Sunday', value: WEEK_DAYS.SUNDAY },
]

export const ReportSendTime = '9am UTC'
export const ReportSendText = `We crunch a lot of numbers to generate your report, so it may take a few minutes
after ${ReportSendTime} to arrive at the provided addresses.`
export const ReportGeneratedNotice = `
<div data-cy="report-modal-title">
  Your report is being generated.
</div>
`
export const REPORT_FILTER_VALUES = {
  ALL_ADS: 'ALL',
  ALL_PLATFORMS: 'ALL',
  ALL_GAMES: 'ALL',
  ALL_CLIENTS: 'ALL',
  ALL_COUNTRIES: 'ALL',
}

export const REPORT_LAST_STATUS_LABELS = {
  [REPORT_LAST_STATUS.IN_PROGRESS]: 'In progress',
  [REPORT_LAST_STATUS.FAILED]: 'Failed',
  [REPORT_LAST_STATUS.SUCCESSFUL]: 'Successful',
  [REPORT_LAST_STATUS.SCHEDULED]: 'Scheduled',
}

export const SSO_LOGIN_KEYS = {
  ID_TOKEN: 'id_token',
  ACCESS_TOKEN: 'access_token',
  EXPIRES_IN: 'expires_in',
  REFRESH_TOKEN: 'refresh_token',
}

export const GAME_PLATFORM_OS_LABELS = {
  WINDOWS: 'Desktop - PC',
  OSX: 'Desktop - OSX',
  IOS: 'Mobile - iOS',
  ANDROID: 'Mobile - Android',
  PLAYSTATION: 'Console - Sony PlayStation',
  XBOX: 'Console - Microsoft Xbox',
}

export const GAME_OS_LABELS = {
  WINDOWS: 'PC',
  OSX: 'OSX',
  IOS: 'iOS',
  ANDROID: 'Android',
  PLAYSTATION: 'Sony PlayStation',
  XBOX: 'Microsoft Xbox',
}

export const OS_TO_PLATFORM = {
  [PlatformOsEnum.WINDOWS]: GamePlatformEnum.PC,
  [PlatformOsEnum.OSX]: GamePlatformEnum.PC,
  [PlatformOsEnum.IOS]: GamePlatformEnum.MOBILE,
  [PlatformOsEnum.ANDROID]: GamePlatformEnum.MOBILE,
  [PlatformOsEnum.PLAYSTATION]: GamePlatformEnum.CONSOLE,
  [PlatformOsEnum.XBOX]: GamePlatformEnum.CONSOLE,
}

export const HEADER_DROPDOWN_LINK_LABELS = {
  YOUR_ORGANIZATION: 'Your Organization',
  TRANSACTIONS: 'Transactions',
  GAME_RATES: 'Game rates',
  DOWNLOAD_SDK: 'Download SDK',
  ADVANCED_SETTINGS: 'Advanced Settings',
  INVENTORY_AVAILABILITY: 'Inventory Availability',
  INVENTORY_AVAILABILITY_AUDIT_LOG: 'Availability Audit Log',
}

export const AD_FILTER_VALUES = {
  ANY_GAME_TITLE: 'ANY',
}

export const fpAdOpsContact = 'adops@frameplay.com'

export const fpAdTraffickers = [
  'j.rastrullo@frameplay.gg',
  'h.wilson@frameplay.com',
]

export const canEditCostSettingsUserIDs = [
  'd3a09dff-9766-4f44-a033-927cadc9641e',
  '8f236ff2-45ef-4062-a454-2d7f28edafc1',
  '19ea389d-658e-4932-8225-1100486cb691',
  '8555bc46-9064-4b69-901d-81b35edb4a58',
  'e36c9194-f632-4b63-a955-eee182f55835',
]

export const thirdPartyAdPlatformCostProviderTextMap = {
  [ThirdPartyAdPlatformCostProviderEnum.SSP_MAGNITE]: 'SSP - Magnite',
  [ThirdPartyAdPlatformCostProviderEnum.AUDIENCE_DATA_LOTAME]:
    'Audience Data - Lotame',
  [ThirdPartyAdPlatformCostProviderEnum.AUDIENCE_DATA_TRANSUNION]:
    'Audience Data - Transunion',
}

export const SDK_GAME_ENGINES = {
  COCOS: 'Cocos',
  UNITY: 'Unity',
  UNREAL: 'Unreal',
}

export const gameEngineNameToChangelogUrlMap = {
  [SDK_GAME_ENGINES.COCOS]: 'https://docs.frameplay.gg/cocos2dx/changelog/',
  [SDK_GAME_ENGINES.UNREAL]: 'https://docs.frameplay.gg/unreal/changelog/',
}

export const gameEngineNameToUpgradeGuideUrlMap = {
  [SDK_GAME_ENGINES.UNITY]: 'https://docs.frameplay.gg/unity/upgrading/',
}
